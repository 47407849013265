import { Controller } from "@hotwired/stimulus"
import { enablePageScroll, disablePageScroll } from "scroll-lock"

import Stimulus from "../utils/stimulus"

class DefaultModal extends Controller {
  connect() {
    document.addEventListener(`turbo:before-cache`, this.hide)
    disablePageScroll()
  }

  disconnect() {
    document.removeEventListener(`turbo:before-cache`, this.hide)
    enablePageScroll()
  }

  hide = () => {
    this.modalController.hide()
  }

  hideByOutside(e) {
    if (e.currentTarget === e.target) this.modalController.hide()
  }

  get modalController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      `modal`
    )
  }
}

Stimulus.register(`default-modal`, DefaultModal)
