import { Controller } from "@hotwired/stimulus"
import bodymovin from "lottie-web"

import Stimulus from "../utils/stimulus"

class Animation extends Controller {
  static targets = [`stage`]
  #animation

  connect() {
    const animationName = this.stageTarget.dataset.animationName
    const animationLoop = this.stageTarget.hasAttribute(`data-animation-loop`)
    const animationAutoplay =
      this.stageTarget.hasAttribute(`data-animation-loop`)

    this.#animation = bodymovin.loadAnimation({
      container: this.stageTarget,
      renderer: `svg`,
      loop: animationLoop,
      autoplay: animationAutoplay,
      path: `animations/${animationName}.json`,
    })
  }

  disconnect() {
    this.#animation.destroy()
  }
}

Stimulus.register(`animation`, Animation)
