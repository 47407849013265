import { Controller } from "@hotwired/stimulus"

import Stimulus from "../utils/stimulus"

class CharactersBar extends Controller {
  static targets = [`bar`]

  connect() {
    window.addEventListener(`scroll`, this.winScroll, { passive: true })
  }

  disconnect() {
    window.removeEventListener(`scroll`, this.winScroll, { passive: true })
  }

  winScroll = () => {
    const sticky =
      window.scrollY > window.innerHeight / 2 &&
      window.scrollY < document.body.scrollHeight - window.innerHeight * 1.5
    if (sticky) this.barTarget.classList.add(`--visible`)
    else this.barTarget.classList.remove(`--visible`)
  }
}

Stimulus.register(`characters-bar`, CharactersBar)
