import { Controller } from "@hotwired/stimulus"

import Stimulus from "../../common/utils/stimulus"

class ShareBar extends Controller {
  static values = { url: String, copied: Boolean }

  copyUrl() {
    window.navigator.clipboard.writeText(this.urlValue)
    this.copiedValue = true
    window.setTimeout(() => (this.copiedValue = false), 4000)
  }
}

Stimulus.register(`share-bar`, ShareBar)
