import { Controller } from "@hotwired/stimulus"

import Stimulus from "../../common/utils/stimulus"

class ReadingProgress extends Controller {
  static targets = [`progress`]

  connect() {
    window.addEventListener(`scroll`, this.winScroll, { passive: true })
  }

  disconnect() {
    window.removeEventListener(`scroll`, this.winScroll, { passive: true })
  }

  winScroll = () => {
    const parent = this.element.parentNode
    const { top, height } = parent.getBoundingClientRect()
    const headerStickyHeight =
      window.parseInt(
        window
          .getComputedStyle(document.documentElement)
          .getPropertyValue(`--header-sticky-height`)
      ) || 0

    this.progressTarget.value =
      top < 0 && height > window.innerHeight
        ? (-top * 100) / (height - headerStickyHeight)
        : 0
  }
}

Stimulus.register(`reading-progress`, ReadingProgress)
