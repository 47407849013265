import { Controller } from "@hotwired/stimulus"

import Stimulus from "../../common/utils/stimulus"

// toolbar

class ArticlesPageToolbar extends Controller {
  static values = { selects: Boolean }

  static targets = [`toggle`, `selectOption`, `submit`]

  toggleSelects() {
    this.selectsValue = !this.selectsValue
  }

  updateCounter() {
    const count = this.selectOptionTargets.filter((o) => o.checked).length
    if (count > 0) this.toggleTarget.dataset.count = count
    else delete this.toggleTarget.dataset.count
  }

  flashButton = () => {
    this.submitTarget.classList.add(`--pulse`)

    setTimeout(() => {
      this.submitTarget.classList.remove(`--pulse`)
    }, 1400)
  }
}

Stimulus.register(`articles-page-toolbar`, ArticlesPageToolbar)

// toolbar text

class ArticlesPageToolbarText extends Controller {
  static values = { filled: Boolean }

  static targets = [`input`]

  connect() {
    this.toggleClear()
  }

  toggleClear() {
    this.filledValue = !!this.inputTarget.value
  }

  clear() {
    this.inputTarget.value = ``
    this.toggleClear()
  }
}

Stimulus.register(`articles-page-toolbar-text`, ArticlesPageToolbarText)

// toolbar select

class ArticlesPageToolbarSelect extends Controller {
  static values = { active: Boolean }

  static targets = [`toggle`, `options`, `option`]

  connect() {
    window.addEventListener(`keyup`, this.winKeyup)
    document.addEventListener(`click`, this.docClick)
    this.updateCounter()
  }

  disconnect() {
    window.removeEventListener(`keyup`, this.winKeyup)
    document.removeEventListener(`click`, this.docClick)
  }

  toggle() {
    this.activeValue = !this.activeValue

    this.optionsTarget.tabIndex = 0
    if (this.activeValue) this.optionsTarget.focus()
  }

  activeValueChanged() {
    this.toggleTarget.ariaExpanded = this.activeValue
  }

  winKeyup = (e) => {
    if (e.key == `Escape`) this.activeValue = false
  }

  docClick = (e) => {
    if (this.activeValue && !this.element.contains(e.target))
      this.activeValue = false
  }

  updateCounter = () => {
    const count = this.optionTargets.filter((o) => o.checked).length
    if (count > 0) this.toggleTarget.dataset.count = count
    else delete this.toggleTarget.dataset.count
  }
}

Stimulus.register(`articles-page-toolbar-select`, ArticlesPageToolbarSelect)
