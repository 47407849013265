import { Controller } from "@hotwired/stimulus"
import Swiper, { Pagination, A11y, Keyboard } from "swiper"

import Stimulus from "../../common/utils/stimulus"

// item

class CharactersTeaser extends Controller {
  static values = {
    swipered: Boolean,
    paginationShown: Boolean,
    paginationMsg: String,
  }

  static targets = [`swiper`, `pagination`]

  connect() {
    this.swiperedValue = true

    const swiper = new Swiper(this.swiperTarget, {
      modules: [Pagination, A11y, Keyboard],
      spaceBetween: 20,
      slidesPerView: 1,
      breakpoints: {
        980: {
          slidesPerView: 3,
        },
        400: {
          slidesPerView: 2,
        },
        0: {
          slidesPerView: 1,
        },
      },
      pagination: {
        el: this.paginationTarget,
        clickable: true,
      },
      a11y: {
        paginationBulletMessage: `${this.paginationMsgValue} {{index}}`,
      },
      keyboard: {
        enabled: true,
      },
    })

    swiper.on(
      `paginationUpdate`,
      () => (this.paginationShownValue = swiper.pagination.bullets.length > 1)
    )
  }
}

Stimulus.register(`characters-teaser`, CharactersTeaser)
