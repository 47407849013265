import initSmartOutline from "@oddcamp/js-utils/src/smart-outline"

initSmartOutline([
  `input:focus`,
  `button:focus`,
  `textarea:focus`,
  `select:focus`,
  `.choices.is-focused`,
  `.choices__item.is-highlighted`,
])
