import { Controller } from "@hotwired/stimulus"
import { removeClass, addClass } from "@oddcamp/js-utils/src/attribute"

import Stimulus from "../../common/utils/stimulus"

class TabbedContent extends Controller {
  static values = {
    activeIndex: Number,
  }

  static targets = [`tabButton`, `tabContainer`, `content`]

  #clicked = false
  #onTabSwitchHandlers = []

  connect() {
    if (!this.hasTabContainerTarget) return

    window.addEventListener(`keyup`, this.windowKeyup, { passive: true })
  }

  disconnect() {
    if (!this.hasTabContainerTarget) return

    window.removeEventListener(`keyup`, this.windowKeyup, { passive: true })
  }

  activeIndexValueChanged() {
    if (!this.hasTabContainerTarget) return

    removeClass(this.tabContainerTargets, `--active`)
    addClass(this.tabContainerTargets[this.activeIndexValue], `--active`)

    this.tabButtonTargets.forEach((t) => {
      t.ariaSelected = false
      t.tabIndex = -1
    })
    this.tabButtonTargets[this.activeIndexValue].ariaSelected = true
    this.tabButtonTargets[this.activeIndexValue].tabIndex = 0
    if (this.#clicked) this.tabButtonTargets[this.activeIndexValue].focus()
    this.#clicked = false

    removeClass(this.contentTargets, `--active`)
    const content = this.contentTargets[this.activeIndexValue]
    if (content) addClass(content, `--active`)

    this.#onTabSwitchHandlers.forEach((handler) =>
      handler({ element: this.element, activeIndex: this.activeIndexValue })
    )
  }

  switchTab(e) {
    this.#clicked = true
    this.activeIndexValue = this.tabButtonTargets.indexOf(e.currentTarget)
  }

  windowKeyup = (e) => {
    if (!this.hasTabContainerTarget) return

    if (
      [`ArrowLeft`, `ArrowRight`, `ArrowUp`, `ArrowDown`].includes(e.key) &&
      this.tabButtonTargets.indexOf(document.activeElement) > -1
    ) {
      this.#clicked = true
      let index = this.activeIndexValue
      if ([`ArrowLeft`, `ArrowUp`].includes(e.key)) {
        index--
        this.activeIndexValue =
          index < 0 ? this.tabButtonTargets.length - 1 : index
      } else {
        index++
        this.activeIndexValue =
          index >= this.tabButtonTargets.length ? 0 : index
      }
    }
  }

  onTabSwitch(handler) {
    this.#onTabSwitchHandlers.push(handler)
  }
}

Stimulus.register(`tabbed-content`, TabbedContent)
