import { Controller } from "@hotwired/stimulus"

import Stimulus from "../../common/utils/stimulus"

class FormFile extends Controller {
  static values = {
    selectedText: String,
    selected: { type: Boolean, default: false },
  }

  static targets = [`placeholder`]

  #placeholderHtml = ``

  connect() {
    this.#placeholderHtml = this.placeholderTarget.innerHTML
  }

  changed(e) {
    let text = ``

    if (e.target.files && e.target.files.length > 1)
      text = (this.selectedTextValue || ``).replace(
        `{{COUNT}}`,
        e.target.files.length
      )
    else text = e.target.value.split(`\\`).pop()

    this.placeholderTarget.innerHTML = text || this.#placeholderHtml
    this.selectedValue = !!(e.target.files && e.target.files.length)
  }
}

Stimulus.register(`form-file`, FormFile)
