import { delegateEventListener } from "@oddcamp/js-utils/src/event"
import { Controller } from "@hotwired/stimulus"
import Cookies from "js-cookie"

import Stimulus from "../../common/utils/stimulus"

class CookieBar extends Controller {
  static values = { name: String }

  accept() {
    this.set(`accept`)
  }

  deny() {
    this.set(`deny`)
  }

  set = (value) => {
    Cookies.set(this.nameValue, value, { expires: 365 })
    this.element.classList.add(`hidden`)
  }
}

Stimulus.register(`cookie-bar`, CookieBar)

// resetter

delegateEventListener(`.js--cookie-consent-reset`, `click`, (e, triggerEl) => {
  e.preventDefault()
  Cookies.remove(triggerEl.dataset.cookieName)
  // window.scrollTo(0, 0)
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: `instant`,
  })
  window.location.reload()
})
