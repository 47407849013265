import { Controller } from "@hotwired/stimulus"

import Stimulus from "../utils/stimulus"

class ArticleTOC extends Controller {
  // Query all headings in the article body
  #articleHeadings = document
    .getElementById(`article-body`)
    .querySelectorAll(`h1, h2, h3, h4, h5, h6`)

  connect() {
    // If article has no headings, skip
    // check for empty headings, and don't count those
    let count = 0
    this.#articleHeadings.forEach((h, i) => {
      if (!h.innerText) return
      else count++
    })

    if (count === 0) return

    // Create and append the title for the TOC
    const tocTitle = document.createElement(`h2`)
    tocTitle.innerText = `In this article`
    this.element.append(tocTitle)

    // Create and append the TOC list
    const tocList = document.createElement(`ul`)
    this.element.append(tocList)

    // Cycle through all article headings
    this.#articleHeadings.forEach((h, i) => {
      // if heading is empty, skip this
      if (!h.innerText) return

      // Set heading ID
      const headingId = `heading-${i}`

      // Create one li for each heading in the article
      const tocHeading = document.createElement(`li`)

      // Create one a for each heading in the article
      const tocLink = document.createElement(`a`)

      // Set the ID for this heading
      h.id = headingId

      // Set the link on the TOC item to the corresponding heading
      tocLink.href = `#${headingId}`

      // Set the link's title the same as the heading
      tocLink.innerText = h.outerText

      // Add link to the list item
      tocHeading.append(tocLink)

      // Add the list item to the TOC list
      tocList.append(tocHeading)
    })
  }
}

Stimulus.register(`article-toc`, ArticleTOC)
