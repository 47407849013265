import Stimulus from "./stimulus"

const modal = {
  getLoaderController(idOrElement) {
    const element =
      typeof idOrElement == `string`
        ? document.querySelector(
            `[data-modal-loader-id-value="${idOrElement}"]`
          )
        : idOrElement

    return element
      ? Stimulus.getControllerForElementAndIdentifier(element, `modal-loader`)
      : null
  },

  show(idOrElement) {
    const controller = this.getLoaderController(idOrElement)
    if (controller) controller.show()
  },

  hide(idOrElement) {
    const controller = this.getLoaderController(idOrElement)
    if (controller) controller.hide()
  },
}

export default modal
