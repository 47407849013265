import { delegateEventListener } from "@oddcamp/js-utils/src/event"

delegateEventListener(`[data-linkable]`, `click`, (e, target) => {
  if (e.defaultPrevented) return
  if (e.target.closest(`a, button, label, input, select, textarea`)) return

  const url = (target.dataset.linkable || ``).trim()
  if (!url) return

  if (e.ctrlKey || e.metaKey) {
    window.open(url, `_blank`)
  } else if (window.Turbo) {
    window.Turbo.visit(url)
  } else {
    window.location.href = url
  }
})
